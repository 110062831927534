import { Controller } from "stimulus"

import tippy from 'tippy.js'
import {roundArrow} from 'tippy.js';

export default class extends Controller {
  static targets = []

  connect() {
    this.tippy = tippy(this.element, {
      content: this.data.get('content'),
      theme: 'dark',
      arrow: roundArrow,
      // hideOnClick: false, // dev
      // trigger: 'click'    // dev
    })
  }

  disconnect() {
    this.tippy.destroy()
  }
}
