import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button']

  connect() {
  }

  disconnect() {
    this.close()
  }

  close() {
    this.element.remove()
  }
}
