// NOTE: Trix is initialized whenever a trix-editor element enters the DOM.
// To workaround this we add the trix-toolbar and trix-editor markup to the controller dataset
// and append it as soon as turbolinks is loaded to avoid a duplicated toolbar.

import { Controller } from "stimulus"

import Trix from 'trix'

export default class extends Controller {
  static targets = [ 'editor' ]

  connect() {
    // Add heading2 toolbar button
    Trix.config.blockAttributes.heading2 = { tagName: "h2", terminal: true, breakOnReturn: true, group: false }

    // Do not auto add name and file size to image caption
    Trix.config.attachments.preview.caption = {
      name: false,
      size: false
    }

    // Append trix-toolbar and trix-editor
    this.element.innerHTML = this.data.get('toolbar') + this.data.get('editor')
    this.editorTarget.addEventListener("trix-initialize", this.trixInitialize)
    this.editorTarget.addEventListener('trix-file-accept', this.trixFileAccept)
  }

  disconnect() {
    this.editorTarget.removeEventListener("trix-initialize", this.trixInitialize)
  }

  // NOTE: This is not really necessary yet, neither is initializing multiple trix controllers as
  // Trix does not support different configs for different editor instances (go figure)
  // But we still do this in case for future support or we switch to something else.
  trixInitialize = (event) => {
    let editor = this.editorTarget.editor
    // NOTE: For using div tags as default, update trix and enable this config
    // See: https://github.com/basecamp/trix/issues/202
    if (this.data.get("processor") == 'word') {
      this.editorTarget.classList.add('is-word')
      Trix.config.blockAttributes.default.breakOnReturn = true
    }
  }

  trixFileAccept = (event) => {
    if (this.data.get('attachments') == 'true') {
      // # do not allow attachments with mime type other than the following below
      // # if event.file.type not in ["image/jpeg", "image/jpg", "image/png", "image/gif", "application/pdf"]
      // #   event.preventDefault()
      // # do not allow attachments with a bigger file size than 5 megabytes
      // if (event.file.size > 5*1024*1024)
      //   event.preventDefault()
    } else {
      // do not allow attachments if trix-editor doesn't have data-trix-options='allow_attachments'
      event.preventDefault()
    }
  }
}
