import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'popover']

  initialize() {
    this.boundCloseHandleEvent = this.closeHandleEvent.bind(this);
  }

  connect() {
    document.addEventListener("click", this.boundCloseHandleEvent, true)
  }

  disconnect() {
    document.removeEventListener("click", this.boundCloseHandleEvent, true)
  }

  close(event) {
    if (this.hasPopoverTarget) {
      this.buttonTargets.forEach((el) => {
        el.classList.remove('is-active')
        el.dataset.target = el.dataset.target.replace(' current-popper.button', '')
      })
      this.popoverTarget.querySelector('div[data-popper-arrow]').remove()
      this.popoverTarget.style.display = 'none'
      this.popoverTarget.dataset.target = this.popoverTarget.dataset.target.replace(' current-popper.popover', '')
    }
  }

  closeHandleEvent(event) {
    var matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
    }

    if (!matches(event.target, "[data-target~='popper.button'], [data-target~='popper.button'] *, [data-target~='popper.popover'], [data-target~='popper.popover'] *")) {
      this.close()
    }
  }
}
