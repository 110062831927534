import { Controller } from "stimulus"
import consumer from "../../channels/consumer"
import {loadStripe} from '@stripe/stripe-js';

export default class extends Controller {
  static targets = [ "email", "card", "errors", "submit" ]

  async connect() {
    const stripe = await loadStripe(this.data.get('pk'))

    let StripeController = this
    let form = this.element

    // Setup stripe
    this.stripe    = Stripe(this.data.get("pk"), {
      stripeAccount: this.data.get("account")
    })
    const elements = this.stripe.elements()
    const style    = JSON.parse(this.data.get("style"))

    // Setup card field
    this.card = elements.create("card", { style: style })
    this.card.mount(this.cardTarget)

    // Connect websocket
    this.subscription = consumer.subscriptions.create(
      {
        channel: "OrderChannel",
        key: this.data.get('client-secret')
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },
        disconnected() {
          // Called when the subscription has been terminated by the server
        },
        received(data) {
          StripeController.disconnect()
          form.submit()
        }
      }
    )
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  change(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
      this.submitTarget.disabled = true
    } else {
      this.errorsTarget.textContent = ""
      this.submitTarget.disabled = false
    }
  }

  emailIsValid() {
    let regex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
    let valid = !!(this.emailTarget.value.match(regex))
    let error = this.emailTarget.closest('.field').querySelector('.help')
    if (valid) {
      error.classList.add('is-hidden')
    } else {
      error.classList.remove('is-hidden')
    }
    return valid
  }

  submit(event) {
    event.preventDefault()

    if (this.emailIsValid()) {
      // Disable submit
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('is-loading')

      // Confirm card payment
      this.stripe.confirmCardPayment(this.data.get("client-secret"), {
        payment_method: {
          card: this.card
        },
        receipt_email: this.emailTarget.value,
        setup_future_usage: 'off_session'
      }).then((result) => {
        if (result.error) {
          this.errorsTarget.textContent = result.error.message
          this.submitTarget.disabled = false
          this.submitTarget.classList.remove('is-loading')
        } else {
          this.errorsTarget.textContent = ''
          // We don't do anything here as we wait
          // for the stripe webhook event to get
          // post to our server which broadcasts
          // to the OrderChannel and initiates
          // a form.submit()
          //
          // if (result.paymentIntent.status === 'succeeded') {
          // ...
          // }
        }
      })
    }
  }
}
