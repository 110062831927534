import { Controller } from "stimulus"

import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import Informer from '@uppy/informer'
import ProgressBar from '@uppy/progress-bar'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import AwsS3 from '@uppy/aws-s3'
import DragDrop from '@uppy/drag-drop'

export default class extends Controller {
  static targets = ['file', 'hidden', 'preview', 'drop']

  connect() {
    this.uppy = Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: this.data.get('maxFileSize'),
        allowedFileTypes: this.data.get('allowedFileTypes').split(',')
      },
      onBeforeUpload: (files) => {
        this.element.querySelector('.uppy-ProgressBar').classList.remove('is-hidden')
        let button = this.element.querySelector('.uppy-FileInput-btn')
        button.classList.add('is-loading')
        button.disabled = true
      }
    })
      .use(FileInput, {
        target: this.fileTarget,
        locale: {
          strings: {
            chooseFiles: this.fileTarget.dataset.buttonLabel
          }
        }
      })
      .use(Informer, {
        target: this.fileTarget,
      })
      .use(ProgressBar, {
        target: this.previewTarget.parentNode,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      })
      .use(AwsS3, {
        companionUrl: '/', // will call the presign endpoint on `/s3/params`
      })
      .use(DragDrop, {
        target: this.dropTarget,
        locale: {
          strings: {
            dropHereOr: this.dropTarget.dataset.dropLabel,
            browse: this.dropTarget.dataset.browseLabel
          }
        }
      })

    this.uppy.on('thumbnail:generated', (file, preview) => {
      // show preview of the image using URL from thumbnail generator
      this.previewTarget.src = preview
      this.dropTarget.classList.add('has-image')
    })

    this.uppy.on('upload-success', (file, response) => {
      console.log('uploaded')
      // construct uploaded file data in the format that Shrine expects
      const uploadedFileData = {
        id: file.meta['key'].match(/^shrine\/cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      }

      // set hidden field value to the uploaded file data so that it's submitted
      // with the form as the attachment
      this.hiddenTarget.value = JSON.stringify(uploadedFileData)
    })

    this.uppy.on('complete', (result) => {
      this.uppy.getState().files = {}
      this.element.querySelector('.uppy-ProgressBar').classList.add('is-hidden')
      this.uppy.getState().totalProgress = null

      let button = this.element.querySelector('.uppy-FileInput-btn')
      button.classList.remove('is-loading')
      button.disabled = false
    })
  }
}
