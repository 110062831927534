import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dialog', 'background']

  connect() {
  }

  setContent(id) {
    this.dialogTarget.innerHTML = document.querySelector(`[data-modal='${id}']`).innerHTML
  }

  open() {
    this.dialogTarget.classList.remove('is-hidden')
    this.backgroundTarget.classList.remove('is-hidden')
  }

  close() {
    this.dialogTarget.classList.add('is-hidden')
    this.backgroundTarget.classList.add('is-hidden')
    this.dialogTarget.innerHTML = ''
  }

  esc(event) {
    if (event.keyCode == 27) {
      this.close()
    }
  }
}
