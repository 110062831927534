import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faExclamation, faExclamationTriangle, faCheck,
  faChevronLeft, faChevronRight, faGripVertical, faEllipsisH, faTimes,
  faFolderPlus, faPlus, faUserPlus, faTrash, faUpload, faExternalLinkAlt, faCube, faCubes,
  faVideo, faFileAlt,
  faBolt, faCog, faSearch,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCcVisa, faCcMastercard,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faExclamation, faExclamationTriangle, faCheck,
  faChevronLeft, faChevronRight, faGripVertical, faEllipsisH, faTimes,
  faVideo, faFileAlt, faGripVertical,
  faFolderPlus, faPlus, faUserPlus, faTrash, faUpload, faExternalLinkAlt, faCube, faCubes,
  faCcVisa, faCcMastercard,
  faBolt, faCog, faSearch,
)

config.mutateApproach = 'sync'
dom.watch()
