import { Controller } from "stimulus"

import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'item' ]

  connect() {
    var kind  = this.data.get('kind')
    var group = this.data.get('group')

    var reorder = function(event) {
      var item     = event.item
      var url      = item.dataset.url
      var position = event.newIndex + 1
      var data     = `${kind}[position]=${position}`

      if (typeof group === 'string' || group instanceof String) {
        var group_id = item.closest("[data-target='sortable.group']").dataset.id || null
        data += `&${kind}[${group}_id]=${group_id}`
      }

      Rails.ajax({
        type: "PATCH",
        url: url,
        data: data,
        dataType: 'script',
        success: function(repsonse) {},
        error: function(repsonse) {}
      })
    }

    Sortable.create(this.element, {
      animation: 150,
      group: group,
      handle: '.sortable-handle',
      onUpdate: reorder,
      onAdd: reorder
    })
  }
}
