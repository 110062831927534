import { Controller } from "stimulus"

import currency from 'currency.js'

export default class extends Controller {
  static targets = []

  connect() {
  }

  format() {
    var value = this.element.value
    // Remove all dots except the last one
    value = value.replace(/[.](?=.*[.])/g, "")
    // Format input if value is longer than 0
    if (value.length > 0) {
      this.element.value = currency(value, {separator: "'"}).format()
    }
  }
}
