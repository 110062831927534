import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal']

  connect() {
  }

  openModal(event) {
    event.preventDefault()

    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    )

    modalController.setContent(event.currentTarget.dataset.id)
    modalController.open()
  }
}
